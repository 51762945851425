* {
  word-break: keep-all;
}

.flex-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wd-100{
  width: 100%;
  height: 100%;
}

.main-container {
  margin-top: 20px;
  margin-bottom: 20px;
}