.resistorPage {
    padding: 20px;
    text-align: center;
}

.resistorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    position: relative;
}

.resistorBody {
    width: 200px;
    height: 40px;
    background-color: #F4A460;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
}

.resistorBand {
    width: 5%;
    height: 40px;
    position: absolute;
}

.resistorLeads {
    width: 30px;
    height: 10px;
    background-color: #888; /* 회색 */
    position: relative;
}

.leadLeft {
    border-radius: 5px 0 0 5px;
}

.leadRight {
    border-radius: 0 5px 5px 0;
}

.previewCircle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    margin-right: 8px;
    border: 1px solid black;
}